import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  Divider,
  Typography,
  Grid,
  Stack,
  Button,
  Chip,
  Box,
} from '@mui/material';
import { StyledImported } from '../StyledComponents/StyledCommon';
import { setFileId, setFileOrder } from '../../Redux/ImportedFiles';
import {
  CSV,
  JSON,
  TEXT,
  XML,
  SHOPIFY,
  WooLogo as WOO_COMMERCE,
} from '../../../../constants/endpoints/assests/logos';
import { ImportedFileMenu } from './ImportedFileMenu';

const imageMap = {
  CSV: <img src={CSV} alt='csv img' height='50' width='50' />,
  XML: <img src={XML} alt='xml img' height='50' width='50' />,
  Text: <img src={TEXT} alt='text img' height='50' width='50' />,
  JSON: <img src={JSON} alt='img' height='50' width='50' />,
  SHOPIFY: <img src={SHOPIFY} alt='img' height='50' width='50' />,
  WOO_COMMERCE: (
    <img src={WOO_COMMERCE} alt='img' height='50' width='40' />
  ),
};

const StatusChip = ({ status, active }) => {
  if (status === 'FINISHED' && active === true)
    return (
      <Chip
        label='Fetched'
        size='small'
        sx={{
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#e3f9e3',
          padding: '2px',
          color: '#1db494',
          borderRadius: '30px',
        }}
      />
    );

  if (status === 'ERROR' && active === true)
    return (
      <Chip
        label='Error'
        size='small'
        sx={{
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#FCE7E5',
          padding: '2px',
          color: '#E13F2A',
          borderRadius: '30px',
        }}
      />
    );

  if (status === 'PROCESSING' && active === true)
    return (
      <Chip
        label='Processing'
        size='small'
        sx={{
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#FFF0E7',
          padding: '2px',
          color: '#FF823C',
          borderRadius: '30px',
        }}
      />
    );

  if (status === 'QUEUED' && active === true)
    return (
      <Chip
        label='Processing'
        size='small'
        sx={{
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#FFF0E7',
          padding: '2px',
          color: '#FF823C',
          borderRadius: '30px',
        }}
      />
    );

  if (status === 'DEACTIVE' || active === false)
    return (
      <Chip
        label='Inactive'
        size='small'
        sx={{
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#FFF0E7',
          padding: '2px',
          color: '#FF823C',
          borderRadius: '30px',
        }}
      />
    );

  return null;
};

const ImportedFileCard = ({
  id,
  file_type: fileType,
  status,
  name,
  url,
  active,
  openFileModal,
  order,
}) => {
  
  const dispatch = useDispatch();
  const fileIdSelect = () => {
    dispatch(setFileId(id));
  };
  const fileOrderSelect = () => {
    dispatch(setFileOrder(order));
  };

  return (
    <Grid item lg={3} xs={12} key={id}>
      <StyledImported>
        <div style={{ padding: '10px', height: '118px' }}>
          <Stack direction='row' justifyContent='space-between'>
            <Stack direction='row' alignItems='center'>
              <div>{imageMap[fileType]}</div>
              <Box sx={{ pl: 1 }}>
                <Typography sx={{ fontSize: '16px' }}>
                  {fileType === 'WOO_COMMERCE' ? 'WooCommerce' : fileType}
                </Typography>
                <StatusChip status={status} active={active} />
              </Box>
            </Stack>
            <ImportedFileMenu
              name={name}
              id={id}
              url={url}
              status={status}
              active={active}
              handleOptionClick={openFileModal}
            />
          </Stack>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            margin='12px 0px'
          >
            <Typography
              sx={{
                fontSize: '14px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '60%',
              }}
            >
              {name}
            </Typography>
          </Stack>
        </div>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-around'
        >
          <Link
            to='update'
            className='importedButton'
            style={{
              width: '100%',
              borderTop: '1px solid #dedede',
              textAlign: 'center',
            }}
            // added handles to the link instead of their child buttons to avoid misclicks
            onClick={() => {
              fileIdSelect(id);
              fileOrderSelect(order);
            }}
          >
            <Button
              variant='text'
              color='primary'
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              disableRipple
            >
              Settings
            </Button>
          </Link>
          <Divider orientation='vertical' flexItem />
          <Link
            to={`edit-mapping/${id}`}
            className='importedButton'
            style={{
              width: '100%',
              borderTop: '1px solid #dedede',
              textAlign: 'center',
            }}
            onClick={() => {
              fileIdSelect(id);
              fileOrderSelect(order);
            }}
          >
            <Button
              variant='text'
              color='primary'
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              disableRipple
            >
              Edit Mapping
            </Button>
          </Link>
        </Stack>
      </StyledImported>
    </Grid>
  );
};

export { ImportedFileCard };
