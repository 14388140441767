import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// MUI imports
import { Button, Stack, Typography, Grid, Box } from '@mui/material';
import MergeTypeIcon from '@mui/icons-material/MergeType';
// Internal component imports
import { ImportedFileCard } from './ImportedFileCard';
import { clearInputValue } from '../../Redux/ImportForm/slice';
import { clearInputValueImported } from '../../Redux/ImportedFiles/slice';
import { ImportedFileProjectModal } from './ImportedFileProjectModal';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { updateFeedList } from '../../Redux/FeedSummary';
import { updateOrder , updateLength} from '../../Redux/EditMapping/slice';

// Main function component
const ImportedFileList = ({ data }) => {
  const minOrder = data?.map((el) => el.order).sort((a, b) => a - b);
  const feedData = useSelector((state) => state.feedSummaryRedux?.feedlist);
  const feedlist = useSelector((state) => state.allProjectsRedux.feeds);
  const navigate = useNavigate();
  const { user } = useContext(UserInfoContext);
  const handleImportSettings = () => {
    navigate('add');
  };
  const dispatch = useDispatch();

  const handleClearClick = () => {
    dispatch(clearInputValueImported());
    dispatch(clearInputValue());
  };

  const [modalId, setModalId] = useState(null);
  const [modalFunctionName, setModalFunctionName] = useState(null);

  const closeModal = () => {
    setModalId(null);
  };

  const openModal = (id, method) => {
    setModalId(id);
    setModalFunctionName(method);
  };

  const handleNavigation = () => {
    if (feedData?.length === 0) {
      navigate('../feeds/feed-settings');
    } else {
      navigate('../feeds');
    }
  };
  useEffect(() => {
    dispatch(updateFeedList(feedlist));
  }, [feedlist]);
  useEffect(() => {
    dispatch(updateOrder(minOrder[0]));
    dispatch(updateLength(minOrder?.length))
  }, [data]);
  return (
    <>
      <Grid container spacing={3}>
        {user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR' && (
          <Grid item lg={3} xs={12}>
            <Box
              sx={{
                border: '1px solid #dedede',
                textAlign: 'center',
                borderRadius: '8px',
                minHeight: '155px',
                '&:hover': { border: '1px solid #0096ff' },
              }}
              onClick={() => {
                handleImportSettings();
                handleClearClick();
              }}
            >
              <Button disableRipple sx={{ width: '100%', height: '155px' }}>
                <Stack
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                >
                  <MergeTypeIcon
                    sx={{
                      padding: '10px',
                      color: '#ffffff !important',
                      backgroundColor: '#0096ff',
                      fontSize: '45px !important',
                      borderRadius: '50%',
                    }}
                  />
                  <Typography
                    sx={{ color: '#323C46', marginTop: '10px' }}
                    variant='bodyMedium'
                  >
                    Add new file
                  </Typography>
                </Stack>
              </Button>
            </Box>
          </Grid>
        )}
        {data &&
          data.length > 0 &&
          [...data]
            .sort((a, b) =>
              a.name.localeCompare(b.name, undefined, { numeric: true })
            )
            .map((iData) => (
              <ImportedFileCard
                key={iData.id}
                file_type={iData.file_type}
                name={iData.name}
                id={iData.id}
                status={iData.status}
                url={iData.url}
                active={iData.active}
                openFileModal={openModal}
                order={iData.order}
              />
            ))}
      </Grid>

      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{ marginTop: '40px', marginBottom: '20px' }}
      >
        <Button
          disableRipple
          sx={{
            width: '200px',
            margin: '5px',
            textAlign: 'center',
            textDecoration: 'none',
          }}
          variant='contained'
          onClick={handleNavigation}
          // as={Link}
          // to='../feeds'
        >
          Next
        </Button>
      </Stack>
      <ImportedFileProjectModal
        open={modalId && modalFunctionName}
        id={modalId}
        functionName={modalFunctionName}
        handleClose={closeModal}
      />
    </>
  );
};

export { ImportedFileList };
