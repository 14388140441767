import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Divider,
  Typography,
  Grid,
  Stack,
  Button,
  Chip,
  Box,
  CircularProgress,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { StyledImported } from '../StyledComponents/StyledCommon';
import { setFeedChannel, setFeedId, setFeedName } from '../../Redux/FeedSummary';
import {
  GOOGLE_SHOPPING,
  GOOGLE_DYNAMIC_SEARCH_ADS,
  TIKTOK,
  PINTEREST,
  META_ADS,
  BING,
} from '../../../../constants/endpoints/assests/logos';
import { FeedListFileMenu } from './FeedListFileMenu';
import {
  useEnhanceFeedMutation,
  useUpdateDownloadLinkMutation,
} from '../../../../api/RTK/services/feedItems';

import { UserInfoContext } from '../../../../context/UserInfoContext';
// import { DataSendingModalBaseRTK } from '../../../../components/DataSendingModalBaseRTK';

const imageMap = {
  GOOGLE_SHOPPING: (
    <img src={GOOGLE_SHOPPING} alt='img' height='30' width='30' />
  ),
  GOOGLE_DYNAMIC_SEARCH_ADS: (
    <img
      src={GOOGLE_DYNAMIC_SEARCH_ADS}
      alt='img'
      height='30'
      width='30'
    />
  ),
  TIKTOK: <img src={TIKTOK} alt='img' height='30' width='30' />,
  PINTEREST: <img src={PINTEREST} alt='img' height='30' width='30' />,
  META_ADS: <img src={META_ADS} alt='img' height='30' width='30' />,
  BING: <img src={BING} alt='bing img' height='30' width='30' />,
};

const StatusChip = ({ active }) => {
  if (active === true)
    return (
      <Chip
        label='Active'
        size='small'
        sx={{
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#e3f9e3',
          padding: '2px',
          color: '#1db494',
          borderRadius: '30px',
        }}
      />
    );

  if (active === false)
    return (
      <Chip
        label='Inactive'
        size='small'
        sx={{
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#FFF0E7',
          padding: '2px',
          color: '#FF823C',
          borderRadius: '30px',
        }}
      />
    );

  return null;
};

const caseCorrection = (str) =>
  str
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());


const CircularProgressWithLabel = ({ value }) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CircularProgress variant="determinate" value={value}
    />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="caption" component="div" color="text.secondary">
        {`${Math.round(value)}%`}
      </Typography>
    </Box>
  </Box>
);

const FeedListCard = ({
  id,
  channel: channelType,
  name,
  reloadFunction,
  active,
  feedId,
}) => {
  const [progress, setProgress] = useState(0);
  const [showTick, setShowTick] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const feedIdSelect = () => {
    dispatch(setFeedId(feedId));
  };
  const feedChannelSelect = () => {
    dispatch(setFeedChannel(channelType));
  };
  const feedNameSelect = () => {
    dispatch(setFeedName(name));
  };
  const { company, user } = useContext(UserInfoContext);
  const projectId = useSelector((state) => state.allProjectsRedux.projectId);


  const [
    enhanceFeed,
    { isError: enhanceError,
      isSuccess: enhanceSuccess, }
  ] = useEnhanceFeedMutation();
  const [
    updateDownloadLink,
    { isError: updateError,
      isSuccess: updateSuccess, }
  ] = useUpdateDownloadLinkMutation();

  const runFeed = () => {
    setIsLoading(true);
    setProgress(0);

    enhanceFeed({
      companyId: company?.id,
      projectId,
      feedId: id,
    })
      .unwrap()
      .then(() => {
        setProgress(50);
        return updateDownloadLink({ companyId: company.id, projectId, feedId: id }).unwrap();
      })
      .then(() => {
        setProgress(100);
        setTimeout(() => {
          setIsLoading(false);
        }, 500)
      })
      .catch((error) => {
        console.error('Error in running feed operations:', error);
        setIsLoading(false);
        setProgress(0);
      });
  };

  useEffect(() => {
    if (enhanceSuccess && updateSuccess) {
      setShowTick(true);
      setTimeout(() => {
        setShowTick(false);
      }, 1000);
    }
  }, [enhanceSuccess, updateSuccess]);

  useEffect(() => {
    if (enhanceError || updateError) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 2000);
    }
  }, [enhanceError, updateError]);

  const renderIcon = () => {
    if (isLoading) {
      return <CircularProgressWithLabel value={progress} />;
    } if (showError) {
      return <ErrorIcon sx={{
        color: '#E13F2A !important',
        '&:hover': { color: '#E13F2A !important' },
      }} />;
    } if (showTick) {
      return <CheckCircleIcon sx={{ color: '#0096ff !important' }} />;
    }
    return 'Run Now';
  };

  return (
    <Grid item lg={4} xs={12}>
      <StyledImported>
        <div style={{ padding: '20px 16px', height: '118px' }}>
          <Stack direction='row' justifyContent='space-between'>
            <Stack direction='row' alignItems='center'>
              <div>{imageMap[channelType]}</div>
              <div style={{ marginLeft: '10px' }}>
                <Typography sx={{ fontSize: '16px' }}>
                  {channelType === 'META_ADS'
                    ? 'Meta (Facebook & Instagram)'
                    : caseCorrection(channelType)}
                </Typography>
                <StatusChip active={active} />
              </div>
            </Stack>
            {(user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') && (
              <FeedListFileMenu
                name={name}
                id={id}
                active={active}
                reloadFunction={reloadFunction}
              />
            )}
          </Stack>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            margin='12px 0px'
            height='24px'
          >
            <Typography
              sx={{
                fontSize: '14px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '60%',
              }}
            >
              {name}
            </Typography>
            {(user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') && (
              <Button
                sx={{
                  padding: '0px 8px',
                  fontSize: '14px',
                }}
                onClick={runFeed}
                disabled={isLoading}
              >
                {renderIcon()}
              </Button>
            )}
          </Stack>
        </div>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-evenly'
          className='importedButtonWrap'
          sx={{
            '@media (max-width: 425px)': {
              flexDirection: 'column',
            },
          }}
        >
          <Link to='../feeds/feed-settings' className='importedButton'>
            <Button
              variant='text'
              color='primary'
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              disableRipple
              onClick={() => {
                feedIdSelect(feedId);
                feedChannelSelect(channelType);
                feedNameSelect(name);
              }}
            >
              Settings
            </Button>
          </Link>
          <Divider
            orientation='vertical'
            flexItem
            sx={{
              '@media (max-width: 425px)': {
                borderWidth: 1,
              },
            }}
          />
          <Link to='../feeds/enhance' className='importedButton'>
            <Button
              variant='text'
              color='primary'
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              disableRipple
              onClick={() => {
                feedIdSelect(feedId);
                feedChannelSelect(channelType);
                feedNameSelect(name);
              }}
            >
              Enhance
            </Button>
          </Link>
          <Divider
            orientation='vertical'
            flexItem
            sx={{
              '@media (max-width: 425px)': {
                borderWidth: 1,
              },
            }}
          />
          <Link to='../feeds/quality' className='importedButton'>
            <Button
              variant='text'
              color='primary'
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              disableRipple
              onClick={() => {
                feedIdSelect(feedId);
                feedChannelSelect(channelType);
                feedNameSelect(name);
              }}
            >
              Quality
            </Button>
          </Link>
          <Divider
            orientation='vertical'
            flexItem
            sx={{
              '@media (max-width: 425px)': {
                borderWidth: 1,
              },
            }}
          />
          <Link to='../feeds/preview' className='importedButton'>
            <Button
              variant='text'
              color='primary'
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              disableRipple
              onClick={() => {
                feedIdSelect(feedId);
                feedChannelSelect(channelType);
                feedNameSelect(name);
              }}
            >
              Preview
            </Button>
          </Link>
        </Stack>
      </StyledImported>
      {/* <DataSendingModalBaseRTK
        open={
          enhanceLoading ||
          enhanceError ||
          enhanceSuccess ||
          updateLoading ||
          updateError ||
          updateSuccess
        }
        handleCloseModal={() => {
          if (!enhanceLoading && !updateLoading) {
            enhanceReset();
            updateReset();
          }
        }}
        isLoading={enhanceLoading || updateLoading}
        isError={enhanceError || updateError}
        isSuccess={enhanceSuccess && updateSuccess}
        successMessage='Feed updated'
        errorMessage="Couldn't update feed"
        reset={() => {
          enhanceReset();
          updateReset();
        }}
      >
        <Box sx={{ height: '200px' }} />
      </DataSendingModalBaseRTK> */}
    </Grid>
  );
};

export { FeedListCard,CircularProgressWithLabel };
