/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// MUI imports
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReorderIcon from '@mui/icons-material/Reorder';
import ErrorIcon from '@mui/icons-material/Error';

// Internal imports
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorSnackBar } from '../../../widgets/SnackbarNew/ErrorSnackBar/ErrorSnackBar';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { ImportedFileList } from '../Components/OtherComponents/ImportedFileList';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { useGetAllFilesQuery } from '../../../api/RTK/services/feedFile';
import { PageErrors } from '../../../components/PageErrors';
import { useRunImportsMutation } from '../../../api/RTK/services/feedMapping';
import { ErrorMessageBox } from '../Components/GlobalComponents/ErrorMessageBox';
import { setFiles } from '../Redux/AllProjects';

// Styled imports
import { StyledPaper } from './StyledMainBox';

const CircularProgressWithLabel = ({ value }) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CircularProgress
      variant='determinate'
      value={value}
      sx={{
        transform: 'rotate(-45deg) !important',
        height: '45px !important',
        width: '45px !important',
      }}
    />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: '4px',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant='caption' component='div' color='text.secondary'>
        {`${Math.round(value)}%`}
      </Typography>
    </Box>
  </Box>
);

const ImportedFileSummary = () => {
  const [progress, setProgress] = useState(0);
  const [showTick, setShowTick] = useState(false);
  const [showError, setShowError] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { company, user } = useContext(UserInfoContext);
  const navigate = useNavigate();
  const projectId = useSelector((state) => state.allProjectsRedux.projectId);
  const dispatch = useDispatch();

  const { data, isLoading, isError, error } = useGetAllFilesQuery(
    {
      companyId: company?.id,
      projectId,
    },
    { skip: !company }
  );

  useEffect(() => {
    dispatch(setFiles(data));
  }, [data]);

  const [runImports, { isSuccess, isError: runError }] =
    useRunImportsMutation();
  const runProject = () => {
    setGetLoading(true);
    setProgress(0);
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 90) {
          clearInterval(timer);
          return oldProgress;
        }
        return Math.min(oldProgress + Math.random() * 10, 90);
      });
    }, 200);

    runImports({
      companyId: company?.id,
      projectId,
    })
      .unwrap()
      .then(() => {
        setProgress(100);
        setTimeout(() => {
          setGetLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.error('Error in running feed operations:', err);
        setGetLoading(false);
        setProgress(0);
      });
  };

  const renderIcon = () => {
    if (getLoading) {
      return <CircularProgressWithLabel value={progress} />;
    }
    if (showError) {
      return (
        <ErrorIcon
          sx={{
            color: '#E13F2A !important',
            '&:hover': { color: '#E13F2A !important' },
          }}
        />
      );
    }
    if (showTick) {
      return <CheckCircleIcon sx={{ color: '#0096ff !important' }} />;
    }
    return <PlayCircleOutlineIcon />;
  };

  useEffect(() => {
    if (isSuccess) {
      setShowTick(true);
      setTimeout(() => {
        setShowTick(false);
      }, 1000);
    }
    if (runError) {
      setShowError(true);
      setErrorMessage('Error running imports. Run again');
      setTimeout(() => {
        setShowError(false);
      }, 2000);
    }
    return () => {};
  }, [isSuccess, runError]);

  useEffect(() => {
    if (!isLoading && data) {
      if (data.length === 0) {
        navigate('./add');
      }
    }
  }, [isLoading, data]);
  return (
    <FetchingDisplay
      isLoading={isLoading}
      isError={isError}
      SuccessElement={
        <ErrorBoundary fallback={<PageErrors />}>
          <Container maxWidth='xxl'>
            <TitleCard title='Imported Files'>
              {user?.role !== 'COLLABORATOR' &&
                user?.role !== 'SUB_COLLABORATOR' && (
                  <Button
                    variant='pillOutlined'
                    disableRipple
                    sx={{
                      height: '44px',
                      minWidth: '175px',
                      marginRight: '16px',
                      '&:hover': { svg: { color: '#0096ff !important' } },
                      svg: { paddingRight: '5px' },
                    }}
                    onClick={runProject}
                    disabled={getLoading}
                  >
                    {renderIcon()}
                    Run Project
                  </Button>
                )}
              <Link
                to='all-items'
                style={{ color: '#323C46', textDecoration: 'none' }}
              >
                <Button
                  variant='pillOutlined'
                  disableRipple
                  sx={{
                    height: '44px',
                    minWidth: '175px',
                    '&:hover': { svg: { color: '#0096ff !important' } },
                    svg: { paddingRight: '5px' },
                  }}
                >
                  <ReorderIcon />
                  View all items
                </Button>
              </Link>
            </TitleCard>
            <StyledPaper>
              {data &&
                data.every &&
                data.every((item) => item.active === false || item.status === "ERROR") && (
                  <ErrorMessageBox
                    mainMessage='None of your imports are active without an error.'
                    description='This can result in running ads for out of stock products or not showing ads for the products added after the import date.'
                  />
                )}
              <ImportedFileList data={data} />
            </StyledPaper>
            {runError && (
              <ErrorSnackBar
                message={errorMessage}
                setMessage={setErrorMessage}
              />
            )}
          </Container>
        </ErrorBoundary>
      }
      LoadingElement={<GlobalLoadingComponent />}
      ErrorElement={
        <PageErrors
          isServerError
          code={error?.originalStatus || error?.status}
        />
      }
    />
  );
};

export { ImportedFileSummary };
