import React, { useMemo, useState, useContext, useEffect } from 'react';
// third party imports
import { useSelector, useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Container } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import {
  getAllCountries,
  getTimezonesForCountry,
} from 'countries-and-timezones';
// internal imports
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { FeedsSummaryTable } from '../Components/OtherComponents/FeedsSummaryTable';
import { useDataFetcher } from '../../../Hooks/useDataFetcher';
import { feedManagementService } from '../../../services/feedManagement';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { PageErrors } from '../../../components/PageErrors';
import { StyledPaper } from './StyledMainBox';
import { setFeeds } from '../Redux/AllProjects/slice';
import { updateVal } from '../Redux/ScheduleForm/slice';
import { ErrorMessageBox } from '../Components/GlobalComponents/ErrorMessageBox';
import {
  useEditProjectMutation,
  useGetProjectDetailsQuery,
  useGetScheduleQuery,
} from '../../../api/RTK/services/feedProject';
import { SnackbarWithCta } from '../Components/GlobalComponents/SnackbarWithCta';
import { clearInputValue } from '../Redux/FeedSummary/slice';

const FeedsSummary = () => {
  const [count, setCount] = useState(1);
  const { company, user } = useContext(UserInfoContext);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  // redux store
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );
  const feedSelectId = useSelector((state) => state.feedSummaryRedux.feedId);
  const scheduleUpdateStore = useDebouncedCallback((key, val) => {
    dispatch(updateVal({ key, val }));
  }, 10);

  const scheduleTimezone = useSelector((state) => state.scheduleFormRedux);

  const memoizedArgs = useMemo(
    () => ({
      company_id: company?.id,
      projectId: projectSelectId,
    }),
    [company?.id, projectSelectId]
  );

  const { data: projectDetails, refetch: refetchProject } =
    useGetProjectDetailsQuery(
      {
        companyId: company?.id,
        projectId: projectSelectId,
      },
      { skip: !company }
    );

  const { data: scheduleData } = useGetScheduleQuery({
    companyId: company?.id,
    projectId: projectSelectId,
    feedId: feedSelectId,
  });

  const [editProject] = useEditProjectMutation();

  const [state] = useDataFetcher(
    { isLoading: false, isError: false },
    feedManagementService.getFeedList,
    memoizedArgs,
    count,
    !!company
  );

  const reloadList = () => {
    setCount(count + 1);
  };
  // check condtions for active project & feed
  const projectActive = projectDetails && projectDetails.active;
  const areAllFeedsInactive =
    state.data &&
    state.data.length > 0 &&
    state.data.every((feed) => !feed.active);
  useEffect(() => {
    if (
      (areAllFeedsInactive && projectDetails?.active === true) ||
      (state?.data &&
        state?.data.length === 0 &&
        projectDetails?.active === true)
    ) {
      // Deactivate the project if all feeds are inactive or there is no feed
      editProject({
        companyId: company?.id,
        data: {
          active: false,
          feedproject_id: projectSelectId,
        },
      }).then(() => refetchProject());
    }
  }, [
    state?.data,
    company?.id,
    projectSelectId,
    projectDetails,
    editProject,
    refetchProject,
  ]);

  // filter country code from country name & set timezone
  const filterCountryCode = (countryName) => {
    const allCountries = getAllCountries();

    const filteredCountries = Object.keys(allCountries)
      .filter(
        (countryCode) =>
          allCountries[countryCode].name.toLowerCase() ===
          countryName.toLowerCase()
      )
      .map((countryCode) => ({
        code: countryCode,
        name: allCountries[countryCode].name,
      }));

    return filteredCountries;
  };

  useEffect(() => {
    if (!state.isLoading && state.data) {
      if (state.data.length === 0) {
        dispatch(clearInputValue());
        // navigate('feed-settings');
      }
      dispatch(setFeeds(state.data));
      const activeFeeds = state.data.filter((feed) => feed.active);
      if (activeFeeds.length > 0) {
        const firstCountryName = activeFeeds[0].country;
        const filteredCountryCodes = filterCountryCode(firstCountryName);
        const countryTimezones = getTimezonesForCountry(
          filteredCountryCodes[0]?.code
        );
        const timezone =
          countryTimezones && countryTimezones.length > 0
            ? countryTimezones[0]?.name
            : '';
        scheduleUpdateStore('timezone', timezone);
      }
    }
  }, [state.isLoading, state.data]);

  return (
    <FetchingDisplay
      isLoading={state.isLoading}
      isError={state.isError}
      LoadingElement={<GlobalLoadingComponent />}
      ErrorElement={<PageErrors isServerError code={state?.errorInfo?.code} />}
      SuccessElement={
        <ErrorBoundary fallback={<PageErrors />}>
          <Container maxWidth='xxl'>
            <TitleCard title='Feeds' />
            <StyledPaper>
              {areAllFeedsInactive && (
                <ErrorMessageBox mainMessage='This Project is deactivated because all the feeds are Inactive' />
              )}
              {!areAllFeedsInactive &&
                (!scheduleData || !projectActive) &&
                (user?.role === 'COLLABORATOR' ||
                  user?.role === 'SUB_COLLABORATOR') && (
                  <ErrorMessageBox mainMessage='This Project is in inactive mode.' />
                )}
              {!areAllFeedsInactive &&
                (!scheduleData || !projectActive) &&
                user?.role !== 'COLLABORATOR' &&
                user?.role !== 'SUB_COLLABORATOR' && (
                  <SnackbarWithCta
                    mainMessage={`This project is in inctive mode. Click on 'Activate & Schedule' to activate and run this project once every ${scheduleTimezone.selectOnly} hrs at ${scheduleTimezone.secondSelect} - ${scheduleTimezone.timezone}.`}
                    description='You can choose a different timezone in Settings > Schedule'
                  />
                )}
              <FeedsSummaryTable
                data={state.data}
                reloadFunction={reloadList}
              />
            </StyledPaper>
          </Container>
        </ErrorBoundary>
      }
    />
  );
};

export { FeedsSummary };
