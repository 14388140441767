/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useContext } from 'react'; // , { useState }
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

// MUI imports
import {
  // TableContainer,
  Typography,
  Button,
  Stack,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
} from '@mui/material';

// Styled component import
import {
  StyledEditMappingBox,
  StyledStack,
} from '../../StyledComponents/StyledCommon';
import { MapperTable } from '../../GlobalComponents/MapperTable';
import { JoinOnSelectField } from '../JoinOnSelectField';
import { UserInfoContext } from '../../../../../context/UserInfoContext';
import { useUpdateMappingMutation } from '../../../../../api/RTK/services/feedMapping';
import { useGetFileDetailsQuery } from '../../../../../api/RTK/services/feedFile';
import { formatter, preFormatter } from './utils';
import { mappingValidationSchema } from './validationSchema';

const EditMappingTable = ({
  projectFields,
  importedFields,
  setIsFormDirty,
  setIsFormHasError,
  setPatchValue,
}) => {
  // const mappedProjectFields =importedFields
  //   .filter((field) => field.imports)
  //   .map((f) => f.mapped_to);
  // set join_on to empty string if its existing value does not belong to a selected project field
  const val = importedFields.filter((field) => field.mapped_to);
  const fileSelectId = useSelector((state) => state.importedFilesRedux.fileId);
  const formikRef = useRef();
  const { fileId } = useParams();
  // get persisted value
  const projectFieldPersistVal = useSelector(
    (state) => state.editMappingRedux.projectField
  );
  const addItemsPersistVal = useSelector(
    (state) => state.editMappingRedux.addItems
  );
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  const importOrderSelect = useSelector(
    (state) => state.importedFilesRedux.fileOrder
  );
  const minOrder = useSelector((state) => state.editMappingRedux?.minOrder);
  // const importListLength = useSelector(
  //   (state) => state.editMappingRedux?.importListLength
  // );
  const { company, user } = useContext(UserInfoContext);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('projectField', projectFieldPersistVal);
      formikRef.current.setFieldValue('addItem', addItemsPersistVal);
    }
  }, [formikRef?.current]);

  const [updateMapping, { isLoading }] = useUpdateMappingMutation();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars

  const { data, refetch } = useGetFileDetailsQuery(
    {
      companyId: company?.id,
      projectId: projectSelectId,
      fileId: fileSelectId,
    },
    {
      skip: !fileSelectId || !company,
    }
  );
  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{
          importedFields: preFormatter(importedFields),
          projectFields,
          join_type: data?.join_type || 'APPEND',
          join_on: data?.join_on || ' ',
          projectField: projectFieldPersistVal,
          addItem: addItemsPersistVal,
        }}
        onSubmit={(values) => {
          updateMapping({
            companyId: company.id,
            projectId: projectSelectId,
            fileId,
            ...formatter(values),
          }).then(() => {
            refetch();
            navigate('../');
          });
        }}
        enableReinitialize
        validationSchema={mappingValidationSchema}
      >
        {({ values, isValid, submitCount }) => (
          <Form>
            {importOrderSelect === minOrder ||
            user?.role === 'COLLABORATOR' ||
            user?.role === 'SUB_COLLABORATOR' ? null : (
              <StyledStack
                direction='row'
                sx={{
                  gap: 2,
                  padding: '0px 0px 15px 15px !important',
                  borderBottom: '1px solid #dedede',
                }}
                alignItems='center'
              >
                <Typography> Add Items as</Typography>
                <Field
                  as={Select}
                  name='join_type'
                  value={values.join_type || data?.join_type}
                  className='addNewEditMap'
                >
                  <MenuItem value='APPEND'>New Items</MenuItem>

                  <MenuItem value='JOIN_FIELD' disabled={val?.length === 0}>
                    Combine on Field
                  </MenuItem>
                </Field>
                {values?.join_type === 'JOIN_FIELD' && (
                  <JoinOnSelectField
                    join_type={data?.join_type}
                    join_on={data?.join_on}
                  />
                )}
              </StyledStack>
            )}
            <StyledEditMappingBox sx={{ width: '100%' }}>
              <MapperTable
                setIsFormDirty={setIsFormDirty}
                setIsFormHasError={setIsFormHasError}
                setPatchValue={setPatchValue}
              />
            </StyledEditMappingBox>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              {submitCount > 0 && !isValid && (
                <Typography variant='smallMedium' sx={{ color: 'error.main' }}>
                  Fix all errors before submitting the form
                </Typography>
              )}
            </Stack>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              justifyContent='center'
              alignItems='center'
              sx={{ marginTop: '40px', marginBottom: '20px' }}
              spacing={4}
            >
              <Button
                sx={{
                  width: '200px',
                }}
                variant='outlined'
                component={Link}
                to='../'
              >
                Back
              </Button>

              <Button
                sx={{
                  width: '200px',
                }}
                variant='contained'
                type='submit'
              >
                {user?.role !== 'COLLABORATOR' &&
                user?.role !== 'SUB_COLLABORATOR'
                  ? 'Save & Next'
                  : 'Next'}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
      <Backdrop sx={{ color: '#fff', zIndex: 5 }} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};

export { EditMappingTable };
