import React from 'react';
// MUI imports
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';

// Styled component imports
import { Link, useLocation } from 'react-router-dom';
import { StyledItem } from '../StyledComponents/StyledCommon';

const renderCell = (val) =>
  typeof val === 'object' ? JSON.stringify(val) : val;
const EnhanceItemDetails = ({ item }) => {
  const location = useLocation();
  const getBack = () => {
    if (location.pathname === '/feed-management/feeds/enhance/item-details') {
      return '../feeds/enhance';
    }
    if (location.pathname === '/feed-management/feeds/preview/item-details') {
      return '../feeds/preview';
    }
    return '../all-items';
  };

  if (item) {
    return (
      <>
        <StyledItem>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow className='itemRow'>
                <TableCell sx={{ fontWeight: '500' }}>Field</TableCell>
                <TableCell sx={{ fontWeight: '500' }}>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(item).map((key) => (
                <TableRow key={key} className='itemRow'>
                  {key.length > 35 ? (
                    <TableCell
                      component='th'
                      scope='row'
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '200px',
                      }}
                    >
                      {key}
                    </TableCell>
                  ) : (
                    <TableCell component='th' scope='row'>
                      {key}
                    </TableCell>
                  )}

                  {key === 'image_link' ? (
                    <TableCell>
                      <img src={item[key]} alt='item' height='50' width='50' />
                    </TableCell>
                  ) : (
                    <TableCell>{renderCell(item[key])}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledItem>
        <Button
          variant='outlined'
          component={Link}
          sx={{
            width: '200px',
            margin: '30px auto',
            display: 'flex',
          }}
          to={getBack()}

        >
          Back
        </Button>
      </>
    );
  }
  return 'Error';
};
export { EnhanceItemDetails };
